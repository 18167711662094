import { S3Client, UploadPartCommand } from '@aws-sdk/client-s3';
import TencentCos from 'cos-js-sdk-v5';

// 分片大小：由于AWS对分片大小有特定限制，故这里固定每次5M
const chunkSize = 5 * 1024 * 1024;
// 计算文件Sha1的Worker进程
let sha1Worker: Worker;
// 文件自增ID
let fileIncId = 0;

// S3/COS临时密钥结构
export interface TempCredential {
  access_key_id: string;
  access_key_secret: string;
  security_token: string;
  expiration: number;
}

// 初始化上传请求结构
export interface InitUploadRequest {
  platform: number;
  sha1: string;
  file_name: string;
  file_size: number;
  chunk_size: number;
  uploader: string;
  acl?: string;
}

// 初始化上传响应结构
export interface InitUploadResponse {
  uploaded_size: number;
  upload_id: string;
  credentials: TempCredential;
  url: string;
  app_id: string;
  region: string;
  bucket: string;
  file_uri: string;
  id: number;
  host: string;
}

// 云厂商平台常量定义，与后台保持一致
export const Platform =  {
  Qcloud: 1,
  Aws: 2,
  Azure: 3,
  Gcp: 4,
};

// 分片上传请求
export interface UploadRequest {
  app_id: string;
  platform: number;
  sha1: string;
  file_size: number;
  upload_id: string;
  chunk: number;
  etag: string;
}

// 分片上传响应
export interface UploadResponse {
  uploaded_size: number;
  url: string;
  credentials: TempCredential;
}

// 初始化上传回调函数结构
export type InitUploadCallback = (req: InitUploadRequest) => Promise<InitUploadResponse>;

// LSCOS上传Client
export default class Client {
  private file: File | null = null;
  private currFileId = 0;
  private initUpload: InitUploadCallback | null = null;
  private retryLimit = 5;
  private initUploadRequest: InitUploadRequest = {
    platform: Platform.Aws,
    sha1: '',
    file_name: '',
    file_size: 0,
    chunk_size: chunkSize,
    uploader: '',
    acl: '',
  };
  private events = {
    error: [] as ((reason: string) => void)[],
    sha1: [] as ((percent: number) => void)[],
    update: [] as ((percent: number) => void)[],
    success: [] as ((url: string) => void)[],
  };
  private abortController: AbortController | null = null;
  private isUploading = false;
  private lastClient: { credential: TempCredential; region: string; client?: S3Client | TencentCos; } = {
    credential: { access_key_id: '', access_key_secret: '', security_token: '', expiration: 0 },
    region: '',
  };
  /**
   * 开始上传，同一Client每次只能上传一个文件
   * @param file 文件对象
   * @param initUpload 初始化上传函数实现，一般由开发者调用自身业务后台，得到临时密钥等参数。开发者也可通过该函数取得文件Sha1等数据
   * @param platform 云平台
   * @param uploader 上传者，如果业务不希望通过后台获取用户ID，可通过前端传入。但还是建议使用后台方式取得
   * @param retryLimit 分片上传重试次数
   * @param acl 文件权限控制，默认为public-read，业务如果需加密数据需改成private
   */
  public startUpload(file: File, initUpload: InitUploadCallback, platform = Platform.Aws, uploader = '', retryLimit = 5, acl = '') {
    if (this.isUploading) {
      this.stopUpload();
    }
    this.file = file;
    this.initUpload = initUpload;
    fileIncId += 1;
    this.currFileId = fileIncId;
    this.retryLimit = retryLimit;
    this.initUploadRequest.platform = platform;
    this.initUploadRequest.uploader = uploader;
    this.initUploadRequest.file_name = file.name;
    this.initUploadRequest.file_size = file.size;
    this.initUploadRequest.acl = acl;
    sha1Worker.addEventListener('message', this.sha1Handler);
    sha1Worker.postMessage({ type: 'start', params: { file, chunkSize }, id: this.currFileId });
    this.isUploading = true;
  }
  /**
   * 中止上传
   * 中止上传时，需同步Sha1 Worker中止计算、并通过AbortController中止所有S3/COS等正在进行的上传请求（如有）
   */
  public stopUpload() {
    sha1Worker.postMessage({ type: 'stop', id: this.currFileId });
    sha1Worker.removeEventListener('message', this.sha1Handler);
    this.file = null;
    this.currFileId = -1;
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }
    this.isUploading = false;
  }
  /**
   * 监听上传事件
   * @param eventType 事件类型：error(出错), sha1(sha1计算进度), update(上传进度), success(上传成功)
   * @param cb 回调函数，error/succcess事件类型时参数为string，upload/sha1事件类型时参数为number
   * @returns this
   */
  public addEventListener(eventType: 'error' | 'sha1' | 'update' | 'success', cb: (reason: string | number) => void) {
    this.events[eventType].push(cb);
    return this;
  }
  /**
   * 移除事件监听
   * @param eventType 事件类型
   * @param cb 回调函数
   * @returns this
   */
  public removeEventListener(eventType: 'error' | 'sha1' | 'update' | 'success', cb: (reason: string | number) => void) {
    this.events[eventType].splice(this.events[eventType].indexOf(cb), 1);
    return this;
  }
  // Worker发送的Sha1计算回调处理：使用Worker进行计算量大的运算，避免阻塞主进程，提升页面流畅度
  private sha1Handler = (e: MessageEvent) => {
    // Worker传入数据结构：
    // type 事件类型：progress=计算进度更新、error=出错、result=计算完成
    // data 事件数据：process时表示计算进度百分比，error时表示出错信息，result时表示计算结果
    // id: 文件ID，如果与当前的不匹配，则忽略该事件
    const { type, data, id } = e.data;
    if (this.currFileId !== id) {
      return;
    }
    if (type === 'progress') {
      this.events.sha1.forEach(cb => cb(data));
    } else if (type === 'error') {
      this.stopUpload();
      this.events.error.forEach(cb => cb(data));
    } else if (type === 'result') {
      sha1Worker.removeEventListener('message', this.sha1Handler);
      this.initUploadRequest.sha1 = data;
      // 计算完SHA1后，即开始上传
      if (this.initUpload) {
        this.initUpload(this.initUploadRequest).then((res) => {
          if (res.uploaded_size >= this.initUploadRequest.file_size) {
            // 文件已经在服务器上存在，无需上传，直接触发success事件
            this.stopUpload();
            this.events.success.forEach(cb => cb(res.url));
          } else {
            // 文件不存在或未传完，开始启动分片上传
            this.upload(res);
          }
        })
          .catch((e) => {
            this.events.error.forEach(cb => cb(e));
          });
      }
    }
  };
  // 获得Aws S3客户端
  private getAwsClient(cred: TempCredential, region: string) {
    return new S3Client({
      region,
      maxAttempts: 0,
      credentials: {
        accessKeyId: cred.access_key_id,
        secretAccessKey: cred.access_key_secret,
        sessionToken: cred.security_token,
      },
    });
  }
  // 获得腾讯云Cos客户端
  private getTencentClient(cred: TempCredential) {
    return new TencentCos({
      Protocol: 'https',
      SecretId: cred.access_key_id,
      SecretKey: cred.access_key_secret,
      SecurityToken: cred.security_token,
      ChunkSize: chunkSize,
    });
  }
  // 获取或更新Client
  private getClient(cred: TempCredential, region = '') {
    const { platform } = this.initUploadRequest;
    const createClient = () => platform === Platform.Aws ? this.getAwsClient(cred, this.lastClient.region) : this.getTencentClient(cred);
    if(region) {
      this.lastClient.region = region;
    }
    if(!this.lastClient.client || this.lastClient.credential.access_key_id !== cred.access_key_id || this.lastClient.credential.access_key_secret !== cred.access_key_secret) {
      this.lastClient.credential = cred;
      this.lastClient.client = createClient();
    }
    return this.lastClient.client;
  }
  // 分片上传
  private async upload(res: InitUploadResponse) {
    const { platform, file_size: fileSize, sha1 } = this.initUploadRequest;
    let client = this.getClient(res.credentials, res.region);
    const uploadRequest: UploadRequest = {
      app_id: res.app_id,
      platform,
      sha1,
      file_size: fileSize,
      upload_id: res.upload_id,
      chunk: 0,
      etag: '',
    };
    const fid = this.currFileId;
    // 断点续传：根据历史以上传的字节数来计算当前从哪个分片开始上传
    const partIndex = Math.floor((res.uploaded_size || 0) / chunkSize);
    const chunkCount = Math.ceil(fileSize / chunkSize);
    console.log('chunkCount', chunkCount, 'chunkSize', chunkSize, 'file_size', fileSize);
    for (let i = partIndex;i < chunkCount;i++) {
      let lastErr = '';
      const partFile = this.file!.slice(i * chunkSize, (i + 1) * chunkSize);
      for (let t = 0;t < this.retryLimit;t++) {
        // 自动重试N次：提升健壮性
        try {
          // 如果闭包中的文件id不等于当前的文件ID，说明上传的文件已经发生改变（例如手动中止上传或更换文件），需停止继续上传
          if (fid !== this.currFileId) {
            return;
          }
          let etag = '';
          this.abortController = new AbortController();
          if (platform === Platform.Aws) {
            // AWS S3分片上传：注意需在云端配置允许跨域、并允许Etag Header返回
            const command = new UploadPartCommand({
              Body: partFile,
              Bucket: res.bucket,
              Key: res.file_uri,
              PartNumber: i + 1,
              UploadId: res.upload_id,
            });
            const awsParams = { abortSignal: this.abortController?.signal, requestTimeout: 60 * 1000 };
            const response = await (client as S3Client).send(command, awsParams);
            // 每次分片会产生一个ETag，用于标识本次上传的分片
            etag = response.ETag!;
          } else {
            // 腾讯云分片上传：需配置允许跨域、并允许Etag Header返回
            const tencentParams = {
              Bucket: res.bucket,
              UploadId: res.upload_id,
              Key: res.file_uri,
              PartNumber: i + 1,
              Body: partFile,
              Region: res.region,
            };
            const response = await (client as TencentCos).multipartUpload(tencentParams);
            etag = response.ETag;
          }
          // etag是分片上传的基础，后续完成上传时需提供每个分片的etag，如果为空则后续流程都无法继续，故直接中断
          if (!etag) {
            throw 'bad etag';
          }
          uploadRequest.chunk = i + 1;
          uploadRequest.etag = etag;
          this.abortController = null;
          break;
        } catch (e) {
          console.error('upload failed, try next', t, e);
          lastErr = `${e}`;
        }
        // 分片上传失败时等待短暂几秒后再试
        await new Promise(resolve => setTimeout(resolve, Math.random() * 5000));
      }
      // 如重试N次还是失败则直接中断
      if (lastErr) {
        this.events.error.forEach(cb => cb(lastErr));
        throw lastErr;
      }
      // 分片直传到云平台后，同步进度到LSCOS文件平台，该接口默认允许跨域，会根据uploadid等数据鉴权
      const uploadRes: UploadResponse = await fetch(`${res.host}/upload`, { mode: 'cors', method: 'POST', body: JSON.stringify(uploadRequest) }).then(res => res.json());
      client = this.getClient(uploadRes.credentials);
      if (i < chunkCount - 1) {
        // 触发上传进度更新事件
        this.events.update.forEach(cb => cb(i / chunkCount));
      } else {
        // 已经是最后一个分片，触发上传完成事件
        this.stopUpload();
        this.events.success.forEach(cb => cb(uploadRes.url));
      }
      await new Promise(resolve => setTimeout(resolve, Math.random() * 5000 + 1000));
    }
  }
}

// 设置Worker的url路径，由于WASM跨域等问题，建议开发者需把examples/lscos-client-worker目录拷贝到自己的服务器中
export function setWorkerPath(url = 'lscos-client-worker/index.js') {
  sha1Worker = new Worker(url);
}
